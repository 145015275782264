import { Option } from '@/common/useForm';

export function sortOptions(data: Option[]) {
  function compare(a: Option, b: Option) {
    if (a.label === `Other` && b.label !== `Other`) {
      return 1;
    }
    if (a.label !== `Other` && b.label === `Other`) {
      return -1;
    }
    return a.label.localeCompare(b.label);
  }
  return data.slice().sort(compare);
}

interface SubOption {
  value: string;
  label: string;
}

export interface AlternateOption {
  value: string | SubOption[];
  label: string;
  code?: string;
  hasSubOptions?: boolean;
}

export function alternateSortOptions(data: AlternateOption[]) {
  function compare(a: AlternateOption, b: AlternateOption) {
    if (a.label === `Other` && b.label !== `Other`) {
      return 1;
    }
    if (a.label !== `Other` && b.label === `Other`) {
      return -1;
    }
    return a.label.localeCompare(b.label);
  }
  return data.slice().sort(compare);
}
