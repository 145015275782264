const orderAwarenessOptions = [
  {
    label: `Company Website`,
    value: `company_website`,
    hasSubOptions: false,
  },
  {
    label: `Practitioner referral (please specify)`,
    value: `other`,
    hasSubOptions: false,
  },
  {
    label: `Social Media`,
    value: [
      {
        label: `Facebook`,
        value: `facebook`,
      },
      {
        label: `Instagram`,
        value: `instagram`,
      },
      {
        label: `LinkedIn`,
        value: `linkedin`,
      },
      {
        label: `TikTok`,
        value: `tiktok`,
      },
      {
        label: `Youtube video`,
        value: `youtube_video`,
      },
    ],
    hasSubOptions: true,
  },
  {
    label: `Email newsletter`,
    value: `email_newsletter`,
    hasSubOptions: false,
  },
  {
    label: `Blog or Article`,
    value: `blog_or_article`,
    hasSubOptions: false,
  },
  {
    label: `Word of mouth`,
    value: `word of mouth`,
    hasSubOptions: false,
  },
  {
    label: `Podcast`,
    value: `podcast`,
    hasSubOptions: false,
  },
  {
    label: `Review site`,
    value: `review site`,
    hasSubOptions: false,
  },
  {
    label: `In-person event/Conference (please specify)`,
    value: `other`,
    hasSubOptions: false,
  },
  {
    label: `Online community/Forum (please specify)`,
    value: `other`,
    hasSubOptions: false,
  },
  {
    label: `Others (please specify)`,
    value: `other`,
    hasSubOptions: false,
  },
];

export default orderAwarenessOptions;
