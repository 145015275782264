/* eslint-disable no-shadow */
/* eslint-disable no-useless-catch */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import axios from 'axios';
import ButtonSpinner from '@/components/icons/ButtonSpinner';
import Link from '@/components/Link';
import { useLocation } from '@reach/router';
import { axiosErrorToast, toastSuccess } from '@/utils/toast';
import { navigate } from 'gatsby';
import AlternateCustomSelect from '@/components/form/alternate';
import useForm, { Option } from '@/common/useForm';
import orderAwarenessSchema from '@/schema/order-awareness-schema';
import PaymentSuccessModal from '../home/popup-modal/payment-success-modal';
import orderAwarenessOptions from './orderAwarenessOptions';

const ratings = [
  {
    src: `/images/crying-face.svg`,
    alt: `crying-face`,
    label: `Very Dissatisfied`,
    value: 1,
  },
  {
    src: `/images/worried-face.svg`,
    alt: `worried-face`,
    label: `Dissatisfied`,
    value: 2,
  },
  // {
  //   src: `/images/neutral-face.svg`,
  //   alt: `neutral-face`,
  //   label: `Neutral`,
  //   value: 3,
  // },
  {
    src: `/images/slightly-smiling-face.svg`,
    alt: `slightly-smiling-face`,
    label: `Satisfied`,
    value: 4,
  },
  {
    src: `/images/happy-face.svg`,
    alt: `happy-face`,
    label: `Very Satisfied`,
    value: 5,
  },
];

export enum Source {
  Platform = `platform`,
  Website = `website`,
}

export default function PaymentSuccess() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRating, setSelectedRating] = useState<number>();
  const [selectedOption] = useState<string>(``);

  const location = useLocation();

  const getQueryParams = (search: string) => new URLSearchParams(search);

  const handleSubmitButton = async (
    sessionId: string,
    source = Source.Website,
  ) => {
    setIsLoading(true);
    try {
      await axios.post(`https://vitract-kit-develop.fly.dev/api/v1/feedback`, {
        sessionId,
        awarenessChannel: selectedOption,
        satisfaction: selectedRating,
        source,
      });
      setIsLoading(false);
      toastSuccess(
        `Thank you for your response! Check your inbox for a one-time 10% off discount code 😁`,
      );
    } catch (error) {
      setIsLoading(false);
      axiosErrorToast(error);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        navigate(`/pricing/`);
      }, 500);
    }
  };

  const {
    handleSubmit,
    handleSelect,
    handleChange,
    handleBlur,
    values,
    errors,
  } = useForm({
    initialValues: { sessionId: ``, awarenessChannel: `` },
    validationSchema: orderAwarenessSchema,
    onSubmit: async (values) => {
      const { sessionId } = values;
      await handleSubmitButton(sessionId);
    },
  });

  return (
    <PaymentSuccessModal
      active
      width="w-full md:w-[490px] h-full md:h-auto overflow-y-scroll md:overflow-hidden rounded-md"
    >
      <div className="flex flex-col p-6">
        <div className="flex flex-col items-center gap-6 pb-6">
          <img
            src="/images/success.svg"
            alt="success"
            className="w-[60px] h-[60px]"
          />
          <h2 className="text-xl text-black font-bold">Payment Successful</h2>
        </div>
        <hr className="border-[#D2D2D2]" />
        <div className="flex flex-col gap-y-6 pt-6">
          <p className="text-base font-medium text-[#484352]">
            Answer these questions to get a 10% discount on your next purchase
            of a Single Gut Test. Help us make Vitract better for everyone.
          </p>

          <div>
            <p className="text-base font-bold pb-3">
              How did you hear about us?
            </p>
            <form action="" onSubmit={handleSubmit}>
              <AlternateCustomSelect
                options={orderAwarenessOptions}
                placeHolder="Please select..."
                onSelect={(option: Option) =>
                  handleSelect(`awarenessChannel`, option)
                }
                onChange={handleChange}
                value={values.awarenessChannel}
                name="awarenessChannel"
                onBlur={() => handleBlur(`awarenessChannel`)}
                error={errors.awarenessChannel}
              />
            </form>
          </div>

          <div>
            <p className="text-base font-bold pb-3">
              How would you rate your overall experience with us?
            </p>
            <div className="w-full flex flex-wrap gap-x-[14px] gap-y-[8px]">
              {ratings.map((rating, index) => (
                <div
                  key={index}
                  className={`w-[87px] md:w-[100px] h-[87px] flex flex-col items-center justify-start p-[10px] cursor-pointer border border-solid hover:border-[#FF713F] rounded-lg ${
                    selectedRating === rating.value
                      ? `border-[#FF713F]`
                      : `border-[#A3A1A8]`
                  }`}
                  onClick={() => setSelectedRating(rating.value)}
                  onKeyDown={() => setSelectedRating(rating.value)}
                  role="button"
                  tabIndex={0}
                >
                  <img src={rating.src} alt={rating.alt} className="w-8 h-8" />
                  <p
                    className={`text-xs font-medium text-center pt-[10px] hover:text-[#FF713F] ${
                      selectedRating === rating.value
                        ? `text-[#FF713F]`
                        : `text-[#A3A1A8]`
                    }`}
                  >
                    {rating.label}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Link
            href="/pricing"
            className="bg-white text-[#484352] text-base py-4 px-6 hover:bg-[#D2D2D2] cursor-pointer rounded-md mt-4 mr-[9px] border border-solid border-[#484352] hover:border-[#A3A1A8]"
          >
            Back to Pricing Page
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            onClick={() => {
              const queryParams = getQueryParams(location.search);
              const sessionId = queryParams.get(`session_id`);
              if (sessionId) {
                handleSubmitButton(sessionId);
              }
            }}
            className="w-[122px] md:w-[144px] bg-primary text-white text-base py-4 px-6 hover:bg-navy-blue cursor-pointer rounded-md mt-4  ml-[9px]"
          >
            {isLoading ? <ButtonSpinner /> : `Submit`}
          </button>
        </div>
      </div>
    </PaymentSuccessModal>
  );
}
