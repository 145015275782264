/* eslint-disable react/require-default-props */
import React from 'react';

interface Props {
  width?: string;
  children: React.ReactNode;
  active: boolean;
}

function PaymentSuccessModal({
  width = `w-full md:w-1/2`,
  children,
  active,
}: Props) {
  return (
    <>
      <div
        className={`${
          active ? `visible` : `hidden`
        } fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-40 transform scale-110 transition-all ease-in-out duration-300`}
      />
      <div
        className={`${
          active ? `visible` : `hidden`
        } mx-auto ${width} z-30 flex flex-col bg-white shadow-md fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 box-border`}
      >
        {children}
      </div>
    </>
  );
}

export default PaymentSuccessModal;
