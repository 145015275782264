/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import PaymentSuccess from '@/views/payment-success';

export default function PaymentSuccessPage() {
  return (
    <div title="Payment Success - Transforming your health through your gut">
      <PaymentSuccess />
    </div>
  );
}
