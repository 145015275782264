/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import { ChangeEvent, FormEvent, MouseEvent, useState } from 'react';
import * as yup from 'yup';

interface Props<T extends FormValues> {
  initialValues: T;
  validationSchema: yup.AnyObjectSchema;
  onSubmit: (values: T) => void;
  onClick?: (values: T) => void;
}

export interface Option {
  value: string;
  label: string;
  code?: string;
}

interface FormValues {
  [key: string]: any;
}

type Errors<T extends FormValues> = {
  [K in keyof T]: string | null;
};

export interface FormProps<T extends FormValues> {
  values: T;
  errors: Errors<T>;
  isCaptchaVerified: boolean;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleFileChange: (name: string, file: File) => void;
  handlePhoneChange: (name: string, value: string) => void;
  handleSelect: (
    name: string,
    option: Option | null,
    dependant?: string[],
  ) => void;
  handleCaptchaChange: (value: string | null) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
  handleBlur: (name: string, dependant?: string[]) => void;
  handleCheckBoxChange: (name: string, value: boolean) => void;
  handleRecaptcha: (name: string, value: string) => void;
  handleUpdateInitialValues: (newValues: T) => void;
  handleMultiSelect: (name: string, value: string | number) => void;
  handleRadioChange: (name: string, value: string | number) => void;
  handleUnitInputChange: (name: string, value: string) => void;
  handleDateChange: (name: string, date: Date | null) => void;
  handleOtpInputChange: (name: string, otp: string) => void;
  handleAccept: (name: string, value: boolean) => void;
  handleMultiCheckBoxChange: (name: string, value: string[]) => void;
  handleReset: () => void;
}

const useForm = <T extends FormValues>({
  initialValues,
  validationSchema,
  onSubmit,
  onClick,
}: Props<T>): FormProps<T> => {
  const [values, setValues] = useState<T>(initialValues);
  const [errors, setErrors] = useState<Errors<T>>({} as Errors<T>);
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);

  const handleCheckBoxChange = async (name: string, value: boolean) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleRecaptcha = async (name: string, value: string) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleFileChange = async (name: string, file: File) => {
    setValues((prevValues) => ({ ...prevValues, [name]: file }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: file });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handlePhoneChange = async (name: string, value: string) => {
    let inputPhoneNumber = value && value.split(` `)[1];
    inputPhoneNumber = inputPhoneNumber.replace(/\D/g, ``);
    let formattedPhoneNumber = ``;

    // Check if the input phone number is not empty
    if (inputPhoneNumber) {
      // Format the phone number based on its length
      if (inputPhoneNumber.length <= 3) {
        formattedPhoneNumber = inputPhoneNumber;
      } else if (inputPhoneNumber.length <= 6) {
        formattedPhoneNumber = inputPhoneNumber.replace(
          /(\d{3})(\d{1})/,
          `$1-$2`,
        );
      } else {
        formattedPhoneNumber = inputPhoneNumber.replace(
          /(\d{3})(\d{3})(\d{1})/,
          `$1-$2-$3`,
        );
      }
    }

    formattedPhoneNumber = `${value.split(` `)[0]} ${formattedPhoneNumber}`;

    // Update the state with the formatted phone number
    setValues((prevValues) => ({
      ...prevValues,
      [name]: formattedPhoneNumber,
    }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleDateChange = async (name: string, date: Date | null) => {
    setValues((prevValues) => ({ ...prevValues, [name]: date }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: date });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleOtpInputChange = async (name: string, otp: string) => {
    setValues((prevValues) => ({ ...prevValues, [name]: otp }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: otp });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleAccept = async (name: string, value: boolean) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleMultiCheckBoxChange = async (name: string, value: string[]) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleBlur = async (name: string, dependant?: string[]) => {
    try {
      await validationSchema.validateAt(name, values);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      if (dependant && dependant.length) {
        for (const item of dependant) {
          setErrors((prevErrors) => ({ ...prevErrors, [item]: `` }));
        }
      }
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      }
    }
  };

  const handleSelect = async (
    name: string,
    option: Option | null,
    dependant?: string[],
  ) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: option ? option.value : initialValues[name],
    }));

    if (dependant && dependant.length) {
      for (const item of dependant) {
        setValues((prevValues) => ({
          ...prevValues,
          [item]: initialValues[item],
        }));
      }
    }

    try {
      await validationSchema.validateAt(name, { [name]: option?.value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      }
    }
  };

  const handleMultiSelect = async (name: string, value: string | number) => {
    if (values[name].includes(value)) {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: prevValues[name].filter((v: string | number) => v !== value),
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: [...values[name], value],
      }));
    }
    try {
      await validationSchema.validateAt(name, {
        [name]: [...values[name], value],
      });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      }
    }
  };

  const handleRadioChange = async (name: string, value: string | number) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleUnitInputChange = async (name: string, value: string) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (errors[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: `` }));
      } catch (error: any) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
      }
    }
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaVerified(!!value);
    setErrors({
      ...errors,
      captcha: null,
    });
  };

  const handleUpdateInitialValues = (newValues: T) => {
    setValues(newValues);
  };

  const handleReset = () => {
    setValues(initialValues); // Reset to initial state
    setErrors({} as Errors<T>);
    setCaptchaVerified(false);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setErrors({} as Errors<T>); // Reset errors to empty object
      onSubmit(values);
    } catch (error: any) {
      const validationErrors: Errors<T> = {} as Errors<T>;
      error.inner.forEach((err: any) => {
        validationErrors[err.path as keyof T] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleClick = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setErrors({} as Errors<T>); // Reset errors to empty object
      onClick && onClick(values);
    } catch (error: any) {
      const validationErrors: Errors<T> = {} as Errors<T>;
      error.inner.forEach((err: any) => {
        validationErrors[err.path as keyof T] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  return {
    values,
    errors,
    isCaptchaVerified,
    handleChange,
    handleFileChange,
    handlePhoneChange,
    handleSelect,
    handleCaptchaChange,
    handleSubmit,
    handleClick,
    handleBlur,
    handleCheckBoxChange,
    handleRecaptcha,
    handleUpdateInitialValues,
    handleMultiSelect,
    handleRadioChange,
    handleDateChange,
    handleUnitInputChange,
    handleOtpInputChange,
    handleAccept,
    handleMultiCheckBoxChange,
    handleReset,
  };
};

export default useForm;
